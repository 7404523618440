import type React from 'react';
import {
  type TooltipProps as RACTooltipProps,
  Tooltip as RACTooltip,
  OverlayArrow,
  composeRenderProps,
  TooltipTrigger,
  type TooltipTriggerComponentProps,
} from 'react-aria-components';
import { tv } from 'tailwind-variants';

const styles = tv({
  base: 'group border border-subtle bg-clear px-3 py-1 text-sm text-copy drop-shadow-sm will-change-transform',
  variants: {
    isEntering: {
      true: 'duration-200 ease-out animate-in fade-in placement-left:slide-in-from-right-0.5 placement-right:slide-in-from-left-0.5 placement-top:slide-in-from-bottom-0.5 placement-bottom:slide-in-from-top-0.5',
    },
    isExiting: {
      true: 'duration-150 ease-in animate-out fade-out placement-left:slide-out-to-right-0.5 placement-right:slide-out-to-left-0.5 placement-top:slide-out-to-bottom-0.5 placement-bottom:slide-out-to-top-0.5',
    },
  },
});

type TooltipProps = Omit<RACTooltipProps, 'children'> & {
  children: React.ReactNode;
  message: React.ReactNode;
  triggerProps?: Omit<TooltipTriggerComponentProps, 'children'>;
};

/**
 * Tooltip component.
 * Siempre tiene que tener como children un <Button />
 */
export function Tooltip({ children, message, className, offset = 10, triggerProps, ...rest }: TooltipProps) {
  return (
    <TooltipTrigger {...triggerProps}>
      {children}
      <RACTooltip {...rest} offset={offset} className={composeRenderProps(className, (className, renderProps) => styles({ ...renderProps, className }))}>
        <OverlayArrow className="flex">
          <svg
            width={8}
            height={8}
            viewBox="0 0 8 8"
            className="fill-inverted stroke-subtle group-placement-left:-rotate-90 group-placement-right:-mr-px group-placement-right:rotate-90 group-placement-bottom:rotate-180 forced-colors:fill-[Canvas] forced-colors:stroke-[ButtonBorder]"
          >
            <path d="M0 0 L4 4 L8 0" />
          </svg>
        </OverlayArrow>
        {message}
      </RACTooltip>
    </TooltipTrigger>
  );
}
