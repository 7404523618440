import { ProgressBar, type ProgressBarProps } from 'react-aria-components';
import { getDomainValue } from 'utils/domain';

function CamperProgressCircle(props: ProgressBarProps) {
  return (
    <ProgressBar {...props}>
      <svg width="16" height="16" viewBox="0 0 24 24" className="block">
        <path fill="currentColor" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" />
        <path
          fill="currentColor"
          d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
        >
          <animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12" repeatCount="indefinite" />
        </path>
      </svg>
    </ProgressBar>
  );
}

function LabProgressCircle(props: ProgressBarProps) {
  return (
    <ProgressBar className="flex items-center justify-center" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14" className="size-3.5 animate-[spin_1.35s_linear_infinite]">
        <path
          fill="currentColor"
          d="M2 6.5h2.5v1H2v-1Zm7.5 0H12v1H9.5v-1Zm-3 3h1V12h-1V9.5Zm0-7.5h1v2.5h-1V2ZM3.111 3.818l.707-.707 1.768 1.768-.707.707-1.768-1.768Zm7.778 6.364-.707.707-1.768-1.768.707-.707 1.768 1.768Zm-6.01-1.768.707.707-1.768 1.768-.707-.707 1.768-1.768Zm3.535-3.535 1.768-1.768.707.707-1.768 1.768-.707-.708Z"
        ></path>
      </svg>
    </ProgressBar>
  );
}

export function ProgressCircle(props: ProgressBarProps) {
  const Component = getDomainValue({ camper: CamperProgressCircle, camperlab: LabProgressCircle, nnormal: CamperProgressCircle });
  return <Component {...props} />;
}
