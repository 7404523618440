export const CloseModalSVG = (props) => (
  <svg height="18" viewBox="0 0 18 18" width="18" {...props}>
    <g fill="none" stroke="currentColor" strokeWidth={1.1} fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path d="m16.664783 17.163575-15.664783-15.663441"></path>
      <path d="m16.664783 1.5-15.664783 15.663441"></path>
    </g>
  </svg>
);

export const CloseSVG = (props) => (
  <svg height={12} viewBox="0 0 10 12" width={10} {...props}>
    <path d="m9.79049 9.789734-9.79049-9.78965m9.79049-.000084-9.79049 9.78965" fill="none" stroke="currentColor" transform="translate(0 1)" />
  </svg>
);

export const CloseWhiteSVG = (props) => (
  <svg height="18" viewBox="0 0 18 18" width="18" {...props}>
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="square" strokeWidth="1.2">
      <path d="m16.664783 16.663575-15.664783-15.663441" />
      <path d="m16.664783 1-15.664783 15.663441" />
    </g>
  </svg>
);

export const CloseMdSVG = ({ closeBgColor = '#000', ...rest }) => {
  return (
    <svg height={18} viewBox="0 0 18 18" width={18} {...rest}>
      <g
        style={{
          stroke: closeBgColor,
          strokeWidth: 1.1,
          fill: 'none',
          fillRule: 'evenodd',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      >
        <path d="m16.664783 17.163575-15.664783-15.663441" />
        <path d="m16.664783 1.5-15.664783 15.663441" />
      </g>
    </svg>
  );
};
